<template>
  <div class="support">
    <div class="supportIcon">
      <div class="container">
        <div class="iconText log">
          <img src="../assets/images/zhichi_icon_rizhi.png" alt="" />
          <span>更新日志</span>
        </div>
        <div @click="feedbackVisible = true" class="iconText">
          <img src="../assets/images/zhichi_icon_fankui.png" alt="" />
          <span>意见反馈</span>
        </div>
      </div>
    </div>
    <div class="updateLog">
      <div class="container">
	<p class="title">《蜘蛛智模|SPDKIT 2.0》更新日志-20210318：</p>
        <div class="txt">
          <p class="versions">
            <span
              >SPDKIT蜘蛛智模专注于BIM模型数据标准化，为BIM全生命周期应用提供规范的数据载体。本次更新主要新增了机电安装模块，柱大样等配筋识别，集成了重庆市地方BIM建模标准，可进行一键审查和更新，进一步提高建模的效率和准确性，更好的进行模型交付及与SPDCOST蜘蛛算量融合，使BIM算量更快更准。
            </span>
          </p>
          <div class="describe">
            <p class="pTitle">【新增功能】</p>
            <div>
              <p>- 新增水系统、暖通、消防、电气系统四大专业功能模块，共计68个功能，涵盖了绘制、布置以及连接调整等各种效率工具，并添加了数百个常用的机电设备、阀门、连接件等构件</p>
              <p>- 模型审查：深入结合重庆市地方BIM标准，与重庆市BIM项目管理平台无缝衔接，在Revit中便可对模型进行提前审查，提高上传平台后的审查通过率</p>
              <p>- 一键加参数：按照审查规则一键添加构件编码以及设置构件默认参数，满足重庆BIM平台审查规则</p>
              <p>- 柱大样识别：识别图纸中的柱大样信息并将其写入柱构件中，可直接进行模型交付及算量</p>
              <p>- IFC导出：将Revit模型导出为带有算量信息的IFC文件，可上传至SPDCOST蜘蛛算量平台进行算量，算量性能更快</p>
            </div>
            <p class="pTitle">【优化功能】</p>
            <div>
              <p>- 翻模工具：支持设置项记忆功能，并自动识别当前楼层标高进行创建</p>
              <p>- 喷淋转化：算法识别算法优化，支持更多类型的图纸转化</p>
              <p>- 配筋工具：优化操作逻辑以及支持更多图纸的识别</p>
              <p>- 间距调整：优化用户使用逻辑，操作更方便、简单</p>
              <p>- 其它功能优化以及BUG修复</p>
            </div>
          </div>
        </div>
    <p class="title pOpen">《蜘蛛智模|SPDKIT 1.5》更新日志-20201111：</p>
        <div class="txt">
          <p class="versions">
            <span
              >蜘蛛智模SPDKIT专注于BIM模型数据标准化，为BIM全生命周期应用提供规范的数据载体。本次更新主要新增了更多构件的标准化建模和图纸的信息识别功能，进一步提高建模的效率和准确性，更好的与蜘蛛算量SPDCOST融合，使BIM算量更快更准。
            </span>
          </p>
          <div class="describe">
			<p class="pTitle">【新版本支持】</p>
			<div>
  <p>
                -
				现支持Revit2016-2021全系版本
  </p>
			</div>
            <p class="pTitle">【新增功能】</p>
            <div>
              <p>- 自动更新：检查最新的SPDKIT版本并进行自动更新，以获取最新功能</p>
              <p>- 楼层设置：增加图纸中楼层表拾取功能</p>
              <p>- 明细表导出：将明细表视图批量导出为Excel文件</p>
              <p>- 构造做法：将图纸中的构造做法信息提取到指定构件参数中</p>
              <p>- 梁表识别：识别图纸中梁表格数据并写入梁属性</p>
              <p>- 板筋识别：识别图纸中板配筋信息并写入板属性</p>
              <p>- 梁自动合并：自动分析模型中可合并的梁进行批量合并</p>
              <p>- 标准化扣减：增加按Revit构件类别进行扣减的功能</p>
              <p>- 构件查重：快速检查模型中部分交叠、重合或包含关系的构件，进行构件一键扣减</p>
              <p>- 工作集分类：根据云平台规则快速创建工作集</p>
              <p>- 标准化建模：增加单桩布置、筏板基础、楼地面做法、抗浮锚杆、锚索锚杆、冠梁、后浇带等快速布置功能</p>
              <p>- 沉降构件区分：用不同颜色来区分降板、降梁等构件</p>
              <p>- 喷淋翻模：识别图纸中喷淋系统的图层信息快速创建喷淋管道、喷淋头等</p>
              <p>- 管道分段：按间距、数量快速批量打断管道</p>
              <p>- 净高分析：分析房间内管线净高并创建净高分析图例，使其通过房间区域的填充颜色区分房间内管线净高高度</p>
            </div>
            <p class="pTitle">【优化功能】</p>
            <div>
              <p>- 梁转化：支持识别集中标注和原位标注，增加合并梁跨的选项</p>
              <p>- 柱、墙表识别：支持保存已识别的数据，识别的表格支持手动匹配柱、墙类型</p>
              <p>- 梁图识别：提升识别率，支持识别原位标注</p>
              <p>- 一键加百叶：修复一键加百叶设置错误</p>
              <p>- 管道避让：支持倾斜管道的自动避让</p>
              <p>- 系统分类：支持自动创建视图过滤器</p>
      <p>- 梁平板：支持旋转梁以使其与板平齐</p>
            </div>
          </div>
        </div>
        <p class="title pOpen">《蜘蛛智模|SPDKIT 1.3》更新日志-20200508：</p>
        <div class="txt">
          <p class="versions">
            <span
              >一键标准化建模，一键算量，是蜘蛛智模SPDKit核心使命；SPDKIT在标准化建模中秉持精益求精的研发态度，在更高效标准化建模同时自动赋予BIM算量所需数据，SPDKIT建立的模型可直接用于算量，无缝对接蜘蛛算量SPDCost平台，真正实现一键建模，一键算量，一模多用。本版本包含如下新增及优化功能：
            </span>
          </p>
          <div class="describe">
            <p class="pTitle">【新增功能】</p>
            <div>
              <p>
                -
                构件库：新增了SPDKIT内置的支持国标的构件资源库，可以在构件库中管理并查看构件属性等
              </p>
              <p>
                -
                构件定义：进行构件库构件快速加载、标准构件创建并定义其属性信息与GB编码
              </p>
              <p>
                -
                信息管理器：(仅限SPDKIT管理员账号)对通用属性进行编辑和管理，并在上传时按照指定的类别进行自动添加
              </p>
              <p>
                - 构件上传：(仅限SPDKIT管理员账号)上传构件并编辑其属性到构件库中
              </p>
              <p>
                -
                构件过滤器：根据标高、类别、清单算量等条件快速过滤构件，构件过滤一清二楚
              </p>
              <p>
                -
                正向设计模块：根据16G101-1中的平法施工图制图规则对基础、柱、梁、剪力墙等构件进行快速定义和高效标准化一键绘制
              </p>
              <p>
                -
                轴网标注：为创建好的轴网进行自动标注和排布轴号，实现轴网的自动化标注
              </p>
              <p>
                - 添加轴号：在轴网中快速插入并自动重排轴号，实现轴号的灵活调整
              </p>
            </div>
            <p class="pTitle">【优化功能】</p>
            <div>
              <p>
                - 功能面板调整：新增【土建】面板，原有土建效率工具移至该面板下
              </p>
              <p>
                -
                标准化扣减：支持扣减规则调整，保存为用户方案，同时扣减效率进一步提高
              </p>
              <p>- 梁配筋信息读取：算法持续优化，钢筋识别准确率率大大提高</p>
              <p>- 楼层设置：生成标高时自动创建对应视图平面，更符合使用习惯</p>
              <p>
                -
                AI识图：该模块中的承台、独立基础、梁、柱等使用带有属性模版的构件进行生成，生成后无需进行复杂设置即可算量
              </p>
              <p>- 其它用户反馈的优化及修复等</p>
            </div>
          </div>
        </div>
        <p class="title pOpen">《蜘蛛智模|SPDKIT 1.2.1》更新日志-20200310：</p>
        <div class="txt">
          <p class="versions">
            <span
              >火神山、雷神山速度让全世界再一次见识了中国建设速度，作为建筑产业数字化生态构建者，我们SPD同样在疫情期间高速前进！
            </span>
          </p>
          <p class="versions">
            <span
              >本次发布提供如下优化及更新，为中国建筑相关从业者提供更加高质量&高效率数字化解决方案，共战疫情，共克时艰：
            </span>
          </p>
          <div class="describe">
            <p class="pTitle">【优化功能】</p>
            <div>
              <p>
                -
                优化了标准化扣减中扣减规则过滤显示，一键高效扣减，让模型更标准化
              </p>
              <p>
                -
                新增后浇带、台阶、坡道、散水等更多构件的扣减规则支持，功能更强大
              </p>
              <p>
                -
                修复模型检查中的错误以及增加检查结果中对缺少的属性红框提醒，让模型检查更方便
              </p>
              <p>
                -
                修复在某些情况下不能正常创建轴网的错误，增加了开间或进深轴网等一键删除功能，让轴网创建更灵活
              </p>
              <p>- 修复AI识图时有些图元不能被识别的错误，模型转换质量更高</p>
              <p>
                -
                新增企业版产品授权策略，调整个人版试用期限为1个月，满足不同用户场景需求
              </p>
              <p>- 其它更多功能的持续完善及优化</p>
            </div>
          </div>
        </div>
        <p class="title pOpen">《蜘蛛智模|SPDKIT 1.2》更新日志-20200108：</p>
        <div class="txt">
          <p class="versions">
            <span>SPDKIT2020正式发布，此次发布推出了面向高校的教育版。</span>
          </p>
          <div class="describe">
            <p class="pTitle">【新增功能】</p>
            <div>
              <p>- SPDKIT教育版本：高校课程专用</p>
              <p>- 柱表提取：将DWG图纸中柱配筋表数据写入柱中</p>
              <p>- 墙表提取：将DWG图纸中柱配筋表数据写入墙中</p>
            </div>
            <p class="pTitle">【优化功能】</p>
            <div>
              <p>- 梁配筋提取：优化DWG识别算法</p>
              <p>- 构件属性：调整构件类型和属性列表</p>
              <p>
                -
                楼层表：优化楼层标高创建逻辑，支持建筑、结构标高同时创建以及通过绝对标高进行创建
              </p>
            </div>
          </div>
        </div>
        <p class="title pOpen">《蜘蛛智模|SPDKIT 1.1.10》更新日志-20191015：</p>
        <div class="txt">
          <p class="versions">
            <span
              >SPDCost云算量产品首次发布，为了使建模更加标准化，提高算量的准确性，实现建模和算量的无缝衔接，SPDKIT此次集成了以下功能：</span
            >
          </p>
          <div class="describe">
            <p class="pTitle">【新增功能】</p>
            <div>
              <p>
                -
                一键上传：将Revit模型上传至SPCView、SPDCost、SPDCI平台，并可在各平台查看、操作模型
              </p>
              <p>
                -
                构件检查：检查模型中构件是否符合云算量要求，可对不符合的构件进行修改
              </p>
              <p>- 算量属性编辑：编辑构件的云算量属性</p>
              <p>- 梁配筋提取：提取DWG图纸中梁配筋信息到对应位置的梁构件中</p>
              <p>- 柱拆分：对跨层的柱构件按标高拆分</p>
              <p>- 墙拆分：对跨层的墙构件按标高拆分</p>
              <p>- 梁合并：合并多个梁构件，要求梁连续且处于一条直线上</p>
              <p>- 标准化扣减：对构件进行有序的扣减，以满足算量计算要求</p>
              <p>- 后浇带布置：在已有模型的基础上快速绘制并创建后浇带</p>
              <p>- 垫层布置：对结构基础类型构件按要求快速生成垫层</p>
              <p>- 柱墩(帽)布置：对柱类型构件快速布置柱墩或者柱帽</p>
              <p>
                -
                开洞：在管线穿墙、穿板或穿梁等位置自动生成洞口，以满足算量计算要求
              </p>
            </div>
          </div>
        </div>
        <p class="title pOpen">《蜘蛛智模|SPDKIT 1.0.10》更新日志-20190815：</p>
        <div class="txt">
          <p class="versions">
            <span
              >"言治骨角者，既切之而复磋之；治玉石者，既琢之而复磨之；治之已精，而益求其精也！”
              SPDKIT秉持工匠精神，在1.0版本基础上持续开发，努力让每个功能更好用、实用；本次更新为阁下呈现如下功能新增及优化。</span
            >
          </p>
          <div class="describe">
            <p class="pTitle">【新增功能】</p>
            <div>
              <p>
                根据大家的反馈，优先开发了呼声高的几个功能，让您建模效率进一步提升：
              </p>
              <p>- 轴网转化：通过拾取CAD图纸中的轴网图层快速生成轴网</p>
              <p>
                -
                重力流管道：通过拾取管道高程表快速生成重力流管网和水井，地下管网海量数据，极速建模
              </p>
              <p>
                -
                点选成板：在梁墙封闭区域内点击成板，更加符合施工规范，模型更加标准化
              </p>
            </div>
          </div>
          <div class="describe">
            <p class="pTitle">【功能优化】</p>
            <div>
              <p>在已有功能基础上持续优化，确保功能的实用、好用：</p>
              <p>- 管道间距调整支持不同标高下的调整</p>
              <p>- 基于AI识图算法的优化，进一步提升了CAD梁转化等准确率</p>
              <p>- 优化系统分类操作逻辑，系统分类更加专业，模型更加标准化</p>
              <p>- 优化三维轴网在拖动剖面框的情况下消失的情况</p>
              <p>- 优化标高重命名默认值，功能使用更方便</p>
              <p>- 优化登陆逻辑，功能使用更方便</p>
            </div>
          </div>
        </div>
        <p class="title pOpen">《蜘蛛智模|SPDKIT 1.0》更新日志-20190704：</p>
        <div class="txt">
          <p class="versions">
            <span
              >创世&诚意之作，谨为阁下呈献如下BIM模型标准化及效率工具，助力您提升效率、降低成本：</span
            >
          </p>
          <div class="describe">
            <p class="pTitle">【通用系列工具】</p>
            <p>提供系列通用工具，助力在各专业建模中的效率：</p>
            <p>- 标高重命名：快速创建标高以及对标高名称进行批量修改</p>
            <p>
              - 类型复制：通过Excel文件模板及指定族类型，批量、极速创建族类型
            </p>
            <p>
              -
              三维轴网：在三维视图中显示轴网，轴网标号随剖面框移动，模型查看更方便
            </p>
          </div>
          <div class="describe">
            <p class="pTitle">【AI识图工具】</p>
            <p>
              借助AI技术及自主研发的CAD识图技术，快速、智能、准确的转化CAD二维信息为BIM标准化构件：
            </p>
            <p>- 桩转化：拾取图纸上的图层快速生成桩</p>
            <p>- 柱转化：拾取图纸上的图层快速生成柱，并可支持常规及异形柱</p>
            <p>
              - 承台转化：拾取图纸上的图层快速生承台，自动识别、创建各类桩形状
            </p>
            <p>- 梁转化：拾取图纸上的图层快速生成梁，支持梁跨、原位标注信息</p>
            <p>- 墙转化：拾取图纸上的图层快速生成墙</p>
            <p>- 门窗转化：拾取图纸上的图层快速生成门窗</p>
          </div>
          <div class="describe">
            <p class="pTitle">【土建效率工具】</p>
            <p>提供二次结构等相关工具，实现土建构件快速建模、深化：</p>
            <p>
              -
              构造柱布置：根据规则快速设置构造柱，规则灵活、可扩展，并支持马牙槎等构造形式
            </p>
            <p>- 圈梁布置：根据规则快速设置圈梁，规则灵活、可扩展</p>
            <p>- 过梁布置：根据规则快速设置过梁，规则灵活、可扩展</p>
            <p>- 梁平板：将梁快速识别、附着到斜屋面或者斜板上</p>
          </div>
          <div class="describe">
            <p class="pTitle">【机电效率工具】</p>
            <p>提供管综系列工具，助力水、暖、电各专业管综效率N倍提升！</p>
            <p>
              -
              系统分类：内置《JGJ/T448-2019建筑工程设计信息模型制图标准》和《DBJ50/T-280-2018重庆市建筑工程信息模型设计标准》管道系统设置标准，让您的模型自动满足国家及地方标准。
            </p>
            <p>- 电力排管：快速排布电力管线</p>
            <p>- 一键加百叶：批量为风管末端添加百叶，并可自动识别管径大小</p>
            <p>
              - 管道避让：选中管道进行快速翻弯并避让其它管道，支持多种避让形式
            </p>
            <p>- 间距检查：检查所选管道之间的各类间距并快速定位查看</p>
            <p>- 间距调整：快速、精确调整所选管道之间的间距</p>
            <p>- 管道坡度：设置整条管网的坡度，让模型更符合实际工程规范</p>
            <p>- 管道保温层：设置保温层添加规则，然后按照规则批量创建保温层</p>
            <p>- 风管对齐：快速进行风管对齐，支持顶、底、边等各种对齐形式</p>
            <p>
              -
              管道标注：可以为单个或多个管道进行标注，同时可以设置标注的内容、样式以及位置
            </p>
          </div>
        </div>
        <!-- <p class="history">历史版本</p>
                <p class="historyVersions">2019.07.01 版本 <img src="../assets/images/zhichi_btn_down.png" alt=""></p> -->
      </div>
    </div>
    <ZzjFeedback v-show="feedbackVisible" @close="feedbackVisible = false" />
  </div>
</template>

<script>
import $ from "jquery";
import ZzjFeedback from "@/components/Feedback.vue";

export default {
  components: {
    ZzjFeedback,
  },
  data() {
    return {
      feedbackVisible: false,
    };
  },
  mounted() {
    $(".pOpen").next().hide();
    $(".title").click(function () {
      $(this).toggleClass("pOpen");
      $(this).next().slideToggle("slow");
    });
  },
};
</script>

<style scoped>
/* supportIcon */
.supportIcon {
  width: 100%;
}
.supportIcon .container {
  width: 1280px;
  display: flex;
  padding: 25px 398px;
  margin: auto;
}
.supportIcon .iconText {
  width: 154px;
  height: 199px;
  padding-left: 24px;
  padding-top: 17px;
  cursor: pointer;
}
.supportIcon .iconText span {
  display: block;
  margin-top: 25px;
  text-align: center;
  width: 107px;
  font-size: 18px;
  color: #666;
}
.supportIcon .log {
  margin-right: 185px;
}
.supportIcon .log:hover {
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
}
.updateLog {
  width: 100%;
  background-color: #f5f5f5;
}
.updateLog .container {
  width: 1280px;
  padding: 40px 138px 30px 138px;
  margin: auto;
  color: #666;
  min-height: 449px;
}
.updateLog .title {
  font-size: 32px;
  margin-bottom: 20px;
  color: #333;
  margin-left: -13px;
  position: relative;
  padding-left: 10px;
  cursor: pointer;
}
.updateLog .txt {
  padding-left: 20px;
}
.updateLog .title::before {
  content: "";
  background: url(../assets/images/right.png) center no-repeat;
  position: absolute;
  width: 16px;
  height: 16px;
  left: 0;
  top: 15px;
  transform: rotate(90deg);
}
.updateLog .pOpen::before {
  content: "";
  transform: initial;
}
.updateLog .versions {
  font-size: 16px;
}
.updateLog span {
  margin-right: 20px;
}
.updateLog .describe {
  font-size: 16px;
  line-height: 30px;
  margin: 23px 0;
}
.updateLog .describe .pTitle {
  margin-left: -5px;
}
.history {
  font-size: 24px;
  color: #333;
}
.historyVersions {
  height: 50px;
  line-height: 50px;
  border-bottom: 2px solid #e9e9e9;
  color: #999;
  font-size: 16px;
}
.historyVersions img {
  margin-left: 24px;
}
</style>